import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery, navigate } from "gatsby";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const isTouch = isMobile || isTablet;

const BlogRoll = ({ data, member = true }) => {
  const { edges: posts } = data.allMarkdownRemark;

  if (!posts) return null;

  const renderPosts = posts.map(({ node: post }, index) => {
    const showImageLeft = !isDesktop || index % 2 === 0;
    let backgroundColor = "#ffffff";
    if (member) {
      backgroundColor = "#0B1413";
      if (index === 1) backgroundColor = "#9BABB4";
      else if (index === 2) backgroundColor = "#CD4F5F";
    }

    const title = post.frontmatter.title;
    const titleParts = title.split(" ");
    const halfRoundedUp = Math.ceil(titleParts.length / 2.0);
    const firstHalfTitle = titleParts.slice(0, halfRoundedUp).join(" ");
    const secondHalfTitle = titleParts.slice(halfRoundedUp).join(" ");

    const imageColumn = (
      <div
        className="column is-4"
        style={{
          position: "relative",
          padding: 0,
        }}
      >
        <PreviewCompatibleImage
          imageInfo={{
            image: post.frontmatter.featuredimage,
            alt: `featured image thumbnail for post ${post.title}`,
          }}
          imageStyle={{ borderRadius: "0px" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: isTouch ? "75%" : "50%",
            height: "2rem",
            backgroundColor: member ? "#ffffff" : "#FF9F1C",
            right: showImageLeft ? "auto" : 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: member ? "#CD4F5F" : "#000" }}>
              Keep Reading →
            </span>
          </div>
        </div>
      </div>
    );

    const textColor = { color: member ? "#fff" : "#000" };
    const postWidth = index !== 1 && !isTouch && member ? "75%" : "100%";

    const postContainerStyle = {
      backgroundColor,
      cursor: "pointer",
      width: postWidth,
    };
    if (member) {
      postContainerStyle.marginBottom = 0;
    }
    return (
      <div
        className="columns is-multiline blog-post-container"
        style={postContainerStyle}
        key={post.id}
        onClick={() => navigate(post.fields.slug)}
        role="presentation"
      >
        {showImageLeft && imageColumn}
        <div className="column is-8">
          <h3 className="title has-text-primary is-size-3">
            <span className="title-script" style={textColor}>
              {firstHalfTitle}{" "}
            </span>
            <span style={textColor}>{secondHalfTitle.toUpperCase()}</span>
          </h3>
          <h5
            className="subtitle is-size-5 is-block has-text-weight-semibold"
            style={textColor}
          >
            {post.frontmatter.date} &bull; {post.fields.readingTime.text}
          </h5>

          <p style={{ marginTop: "-0.5rem", ...textColor }}>{post.excerpt}</p>
        </div>

        {!showImageLeft && imageColumn}
      </div>
    );
  });

  const desktopStyle = {};
  if (member && !isTouch) {
    desktopStyle.display = "flex";
    desktopStyle.flexDirection = "column";
    desktopStyle.alignItems = "center";
  }

  return (
    <div
      style={{
        backgroundColor: member ? "transparent" : "lightgrey",
        padding: member ? 0 : isTouch ? "0.5rem" : "1rem",
        paddingBottom: member ? 0 : "1.5rem",
        ...desktopStyle,
      }}
    >
      {renderPosts}
    </div>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const MainBlogRollFinal = ({ member }) => (
  <StaticQuery
    query={graphql`
      query MainBlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                readingTime {
                  text
                }
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll data={data} count={count} member={true} />
    )}
  />
);

export default MainBlogRollFinal;
