import { useState, useEffect, useRef } from "react";

export default function useDragScroll(initialX) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState(null);
  const ref = useRef(null);

  function onMouseDown(e) {
    setIsMouseDown(true);
    setLastMousePosition(e.clientX);
  }

  useEffect(() => {
    if (initialX && ref.current) {
      ref.current.scrollLeft = initialX;
      setLastMousePosition(initialX);
    }
  }, [initialX]);

  useEffect(() => {
    if (typeof window == "undefined") return;

    function onMouseUp() {
      setIsMouseDown(false);
      setLastMousePosition(null);
    }
    function onMouseMove(e) {
      if (!isMouseDown) return;
      if (ref.current === null) return;
      if (lastMousePosition === null) return;

      ref.current.scrollLeft += lastMousePosition - e.clientX;
      setLastMousePosition(e.clientX);
    }

    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [isMouseDown, lastMousePosition]);

  return {
    ref,
    onMouseDown,
  };
}
