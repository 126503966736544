import * as React from "react";
import { isTablet, isMobile } from "react-device-detect";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import WorkoutDay from "./WorkoutDay";
import WorkoutDetail from "./WorkoutDetail";
import VideoWithPlayButton from "../VideoWithPlayButton";
import videoImage from "../../../static/img/WeeklyWorkout_Hero_MemberPage.png";
import useWorkoutContent from "../../hooks/useWorkoutContent";
import { useFirebase } from "../Firebase";

const isTouch = isMobile || isTablet;

export default function WeeklyWorkout() {
  const firebase = useFirebase();
  const [selected, setSelected] = React.useState(0);
  const { data, isLoading, handleLogSuccess } = useWorkoutContent();
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();

  const isTouchOrMobile = isTouch || isMobile;

  const handleDayClick = React.useCallback((index) => {
    setSelected(index);
    setTimeout(() => {
      let ref;
      if (index === 0) ref = ref1;
      if (index === 1) ref = ref2;
      if (index === 2) ref = ref3;
      if (index === 3) ref = ref4;
      if (index === 4) ref = ref5;

      ref.current?.scrollIntoView({ inline: "center" });
    }, 100);
  }, []);

  const workoutDayNodes = React.useMemo(() => {
    return data.map((item, index) => {
      let ref;
      if (index === 0) ref = ref1;
      if (index === 1) ref = ref2;
      if (index === 2) ref = ref3;
      if (index === 3) ref = ref4;
      if (index === 4) ref = ref5;
      return (
        <WorkoutDay
          key={`workout-day-${item.day}`}
          number={index + 1}
          selected={index === selected}
          onClick={() => handleDayClick(index)}
          title={item.title}
          equipment={item.equipment}
          ref={ref}
        >
          {item}
        </WorkoutDay>
      );
    });
  }, [data, selected, handleDayClick]);

  if (isLoading || data.length === 0 || !firebase) {
    return (
      <div className="weekly-workout-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            height: "100%",
            backgroundColor: "#8f8358",
          }}
        >
          <div className="weekly-workout-video">
            <img src={videoImage} alt={"Weekly workout loading"} />
            <FaSpinner
              className="fa-spin centered banner-play-button"
              color="white"
              size={20}
            />
          </div>
        </div>
      </div>
    );
  }

  const selectedData = data[selected];

  return (
    <div className="weekly-workout-container">
      {/* <VideoWithPlayButton
        imageSrc={videoImage}
        imageAlt={""}
        title={""}
        url={""}
        banner={
          <div style={{ width: "100%" }}>
            <div className="strip"></div>
          </div>
        }
      /> */}
      <div className="weekly-workout-days">
        {workoutDayNodes.slice(0, selected + 1)}

        {isTouchOrMobile === true && (
          <WorkoutDetail
            {...selectedData}
            onLogSuccess={handleLogSuccess}
            key={`workout-detail-${selectedData.day}`}
          />
        )}

        {workoutDayNodes.slice(selected + 1)}
      </div>
      {isTouchOrMobile === false && (
        <WorkoutDetail
          {...selectedData}
          onLogSuccess={handleLogSuccess}
          key={`workout-detail-${selectedData.day}`}
        />
      )}
    </div>
  );
}
