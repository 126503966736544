import * as React from "react";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import { isDesktop } from "react-device-detect";
import BackToBasicsVideo from "../BackToBasicsVideo";

export default function IntroTile() {
  const [showVideo, setShowVideo] = React.useState();
  let slogan = (
    <span className="slogan">
      <span className="strong">STOP</span>
      <span className="gray">
        STARTING<span className="strong">OVER</span>
      </span>
    </span>
  );

  if (!isDesktop) {
    slogan = null;
  }

  return (
    <div className="tile one">
      {showVideo && <BackToBasicsVideo onClose={() => setShowVideo(false)} />}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => setShowVideo(true)}
            className="banner-play-button"
            aria-label="Click or tap to play video"
          >
            <FaRegPlayCircle aria-hidden="true" />
          </button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.70)",
            padding: "0.5rem",
            borderRadius: "4px",
          }}
        >
          <span className="title-script">
            <span className="gold">Get Back to Ba</span>
            <span className="gray">Six</span>
            <span className="gold">!</span>
          </span>
          <div>{slogan}</div>
          {isDesktop && (
            <div>
              <span className="balance-gold">#BALANCE</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
