import * as React from "react";
import { isTablet, isMobile } from "react-device-detect";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";

const isTouch = isMobile || isTablet;

function BalanceBanter({ name }) {
  const [isClient, setClient] = React.useState(false);
  const [data, setData] = React.useState({ title: "", url: null });
  const [, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const isTouchOrMobile = isTouch || isMobile;

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    function getData() {
      setLoading(true);
      fetch(`/.netlify/functions/get-latest-balance-banter`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
        })
        .catch((err) => {
          // error
          setError(true);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (isClient) {
      getData();
    }
  }, [isClient]);

  if (error) {
    return null;
  }

  const header = (
    <div className="greeting-container">
      <span className="greeting">
        Welcome Back, {name.substring(0, name.indexOf(" "))}!
      </span>
    </div>
  );

  const video = data && data.url && (
    <div className="absolute-centered">
      <EmbedYouTubeVideo
        title={data.title || ""}
        url={data.url}
        autoplay
        height={isTouchOrMobile ? "25vh" : "85vh"}
      />
    </div>
  );

  if (isTouchOrMobile) {
    return (
      <div>
        <div>
          <button className="mobile-banner" onClick={() => setPlay(true)}>
            <div className="strip">
              <p className="greeting">
                Welcome back, {name.substring(0, name.indexOf(" "))}!
              </p>
              <p className="watch">Watch the latest #balancebanter</p>
              <p className="title">{(data && data.title) || ""}</p>
            </div>
          </button>
        </div>

        <div className="balance-banter">{video}</div>
      </div>
    );
  }

  return (
    <div className="balance-banter">
      {!!play && video}
      {play === false && (
        <>
          {header}
          <div style={{ width: "100%" }}>
            <button className="banner" onClick={() => setPlay(true)}>
              <div className="strip">
                <p className="watch">Watch the latest</p>
                <p className="title">{(data && data.title) || ""}</p>
                <p className="watch">#balancebanter video</p>
              </div>
            </button>
          </div>
          <button
            className="banner-play-button"
            onClick={() => setPlay(true)}
            aria-label="Click or tap to play video"
          >
            <FaRegPlayCircle aria-hidden="true" />
          </button>
        </>
      )}
    </div>
  );
}

export default BalanceBanter;
