import React from "react";
import { getUnixTime } from "date-fns";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import scrollTo from "gatsby-plugin-smoothscroll";
import MainBlogRoll from "../components/MainBlogRoll";
import PropTypes from "prop-types";
import { useAuthUser } from "../components/Session";
import SocialMediaSlide from "../components/SocialMediaSlide";
import WeeklyWorkout from "../components/WeeklyWorkout";
import BalanceBanter from "../components/Membership/BalanceBanter";
import FoldBar from "../components/FoldBar";
import BackToBasix from "../components/BackToBasix";

function IndexMemberPageTemplate() {
  const authUser = useAuthUser();
  const location = useLocation();
  const { uid } = authUser;
  const [showPopup, setShowPopup] = React.useState(null);
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    function getUserDetails() {
      fetch(
        `/.netlify/functions/get-user-detail?time=${getUnixTime(new Date())}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setShowPopup(!data.data.free_pep_talk_used || false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (isClient && showPopup === null) {
      getUserDetails();
    }
  }, [uid, isClient, showPopup]);

  const scrollAndFillPromo = React.useCallback(() => {
    setTimeout(() => {
      scrollTo("#weekly-workout");
    }, 1000);
  }, []);

  React.useEffect(() => {
    const { section } = queryString.parse(location.search);
    if (section && isClient) {
      scrollAndFillPromo();
    }
  }, [isClient, location, scrollAndFillPromo]);

  return (
    <div className="main-member">
      <BalanceBanter name={authUser ? authUser.username : "User"} />
      <FoldBar
        scriptText="Chat with Kristie!"
        regularText="Feeling off #BALANCE?!"
        showBookNow={true}
      />
      <div className="feature-wrapper back-to-basix">
        <BackToBasix />
      </div>
      <FoldBar
        backgroundColor="#00000080"
        regularText={`GIVE 30 AND GET 30! Refer a friend with code "FRIENDS30" and get a month FREE!`}
      />
      <div id="weekly-workout" />
      <div className="feature-wrapper weekly-workout">
        <WeeklyWorkout />
      </div>
      <FoldBar
        scriptText="Chat with Kristie!"
        regularText="Feeling off #BALANCE?!"
        showBookNow={true}
      />
      <div className="feature-wrapper blogs">
        <MainBlogRoll member={true} />
      </div>
      <FoldBar
        regularText={`GIVE 30 AND GET 30! Refer a friend with code "FRIENDS30" and get a month FREE!`}
      />
      <div className="feature-wrapper social">
        <SocialMediaSlide />
      </div>
    </div>
  );
}

IndexMemberPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

export default IndexMemberPageTemplate;
