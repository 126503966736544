import * as React from "react";
import { isTablet, isMobile } from "react-device-detect";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";

import EmbedYouTubeVideo from "./EmbedYouTubeVideo";

const isTouch = isMobile || isTablet;

export default function VideoWithPlayButton({
  title,
  url,
  imageAlt,
  imageSrc = null,
  header = null,
  banner = null,
}) {
  const [play, setPlay] = React.useState(false);

  // if (isTouch || isMobile) {
  //   return (
  //     <div
  //       style={{
  //         flexDirection: "column",
  //         display: "flex",
  //         height: "100%",
  //         backgroundColor: "black",
  //       }}
  //     >
  //       <div className="weekly-workout-video">
  //         <div className="video-container">
  //           <FaSpinner className="fa-spin" size={50} color="white" />
  //           <EmbedYouTubeVideo title={title} url={url} autoplay />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div style={{ flexDirection: "column", display: "flex", height: "100%" }}>
      <div className="weekly-workout-video">
        {!!imageSrc && (
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{ display: play ? "none" : "block" }}
          />
        )}
        {/* {!!play && (
          <div className="video-container">
            <FaSpinner className="fa-spin" size={50} />
            <EmbedYouTubeVideo title={title} url={url} autoplay />
          </div>
        )}
        {header === null && play === false && (
          <>
            <button
              className="centered banner-play-button"
              onClick={() => setPlay(true)}
              aria-label="Play video"
            >
              <FaRegPlayCircle aria-hidden="true" />
            </button>
            <div
              className="centered banner-play-button"
              style={{ width: "100%", marginTop: "10rem" }}
            >
              {banner}
            </div>
          </>
        )} */}
      </div>
    </div>
  );
}
