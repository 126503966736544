import * as React from "react";
import { useAuthUser } from "../components/Session";
import useCurrentWeek from "../hooks/useCurrentWeek";

export default function useWorkoutContent(all = false) {
  const authUser = useAuthUser();
  const [isClient, setClient] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const weekToDisplay = useCurrentWeek();

  React.useEffect(() => {
    setClient(true);
  }, []);

  const handleLogSuccess = React.useCallback(
    (day) => {
      const updatedItem = listData.find((x) => x.day === day);
      updatedItem.userData.completed = true;

      const newData = listData.filter((x) => x.day !== day);
      newData.push(updatedItem);
      newData.sort((a, b) => (b.day > a.day ? -1 : 1));

      setListData(newData);
    },
    [listData]
  );

  const fetchWorkoutData = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await fetch("/.netlify/functions/get-workouts-for-week", {
        method: "POST",
        body: JSON.stringify({
          week: all ? "all" : weekToDisplay,
          uid: authUser.uid,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await result.json();
      if (json.status === "succeeded") {
        setListData(
          json.data.map((x) => ({ ...x, content: JSON.parse(x.content) }))
        );
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  }, [authUser, all, weekToDisplay]);

  React.useEffect(() => {
    if (isClient) {
      fetchWorkoutData();
    }
  }, [isClient, fetchWorkoutData]);

  return { data: listData, isLoading, handleLogSuccess };
}
