import * as React from "react";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { StaticImage } from "gatsby-plugin-image";
// import image1 from "../img/social-media/KL_SocialMediaSection_2.png";
// import image2 from "../img/social-media/KL_SocialMediaSection_4.png";
// import image3 from "../img/social-media/KL_SocialMediaSection_5.png";
// import image4 from "../img/social-media/KL_SocialMediaSection_6.png";
// import image5 from "../img/social-media/KL_SocialMediaSection_7.png";
// import image6 from "../img/social-media/KL_SocialMediaSection_11.png";
// import image7 from "../img/social-media/KL_SocialMediaSection_13.png";
// import image8 from "../img/social-media/KL_SocialMediaSection_15.png";
// import image9 from "../img/social-media/KL_SocialMediaSection_17.png";
// import image10 from "../img/social-media/KL_SocialMediaSection_20.png";
// import image11 from "../img/social-media/KL_SocialMediaSection_21.png";
// import image12 from "../img/social-media/KL_SocialMediaSection_27.png";

// const IMAGES = [
//   "../img/social-media/KL_SocialMediaSection_2.png",
//   "../img/social-media/KL_SocialMediaSection_4.png",
//   "../img/social-media/KL_SocialMediaSection_5.png",
//   "../img/social-media/KL_SocialMediaSection_6.png",
//   "../img/social-media/KL_SocialMediaSection_7.png",
//   "../img/social-media/KL_SocialMediaSection_11.png",
//   "../img/social-media/KL_SocialMediaSection_13.png",
//   "../img/social-media/KL_SocialMediaSection_15.png",
//   "../img/social-media/KL_SocialMediaSection_17.png",
//   "../img/social-media/KL_SocialMediaSection_20.png",
//   "../img/social-media/KL_SocialMediaSection_21.png",
//   "../img/social-media/KL_SocialMediaSection_27.png",
// ];

export default function SocialMediaSlide() {
  // const imageRows = React.useMemo(() => {
  //   const first = IMAGES.slice(0, 6);
  //   const second = IMAGES.slice(6);
  //   return { first, second };
  // }, []);

  return (
    <div className="social-media-container">
      <div className="instructions">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <FaInstagram
              size={54}
              style={{
                marginRight: "0.5rem",
                marginBottom: "0.5rem",
                color: "#fff",
                verticalAlign: "middle",
              }}
            />
          </span>
          <span className="title">
            <span className="gray">share your experiences </span>
            <span className="gold">on social media</span>
          </span>
        </div>
        <div>
          <span className="description">
            Use <strong>#kristielengel</strong> and your content could be
            featured here!
          </span>
        </div>
      </div>

      <div className="images-container">
        <div>
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_2.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_4.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_5.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_6.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_7.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_11.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_13.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_15.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_17.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_20.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_21.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
          <StaticImage
            src={"../img/social-media/KL_SocialMediaSection_27.png"}
            loading="lazy"
            alt="Instagram feed"
            style={{ border: "0.5rem solid white" }}
          />
        </div>
      </div>
    </div>
  );
}
