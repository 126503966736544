import React from "react";
import Modal from "react-modal";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import EmbedYouTubeVideo from "./EmbedYouTubeVideo";

function BackToBasicsVideo({ onClose }) {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    backgroundColor: "lightgrey",
  };

  return (
    <Modal
      isOpen={true}
      contentLabel="Back to Basics Video"
      style={{ overlay: { zIndex: 31 }, content: customStyles }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="back-to-basics-intro">
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div className="container content">
            <EmbedYouTubeVideo
              url="https://youtube.com/embed/uoB5WGZwZzo"
              title={"Learn more about Back to Basics!"}
              autoplay
            />
          </div>
        </div>
        <div className="close-container">
          <button onClick={onClose} className="fancy">
            <FaTimes
              size={24}
              style={{ verticalAlign: "middle", textAlign: "center" }}
            />{" "}
            Close Window
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BackToBasicsVideo;
