import * as React from "react";
import { parse, addDays, fromUnixTime, isBefore, startOfDay } from "date-fns";
import { useAuthUser } from "../components/Session";

export default function useBasixData() {
  const authUser = useAuthUser();
  const startDate = (authUser && authUser.goal_start_date) || 0;
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  const data = React.useMemo(() => {
    let returnValue = {
      goal1Disabled: true,
      goal2Disabled: true,
      goal3Disabled: true,
      goal4Disabled: true,
      goal5Disabled: true,
      goal6Disabled: true,
      scrollIndex: 0,
    };
    if (isClient && startDate > 0) {
      const lockDate = new Date();
      // parse (format(new Date(), "yyyy-MM-dd"), 'yyyy-MM-dd', new Date())
      returnValue.goal1Disabled = isBefore(
        lockDate,
        addDays(startOfDay(fromUnixTime(startDate)), -1)
      );
      returnValue.goal2Disabled =
        authUser.goal_lock1 === 0 ||
        isBefore(
          lockDate,
          addDays(parse(authUser.goal_lock1, "yyyy-MM-dd", new Date()), 1)
        );
      if (!returnValue.goal2Disabled) returnValue.scrollIndex = 1;

      returnValue.goal3Disabled =
        authUser.goal_lock2 === 0 ||
        isBefore(
          lockDate,
          addDays(parse(authUser.goal_lock2, "yyyy-MM-dd", new Date()), 1)
        );
      if (!returnValue.goal3Disabled) returnValue.scrollIndex = 2;

      returnValue.goal4Disabled =
        authUser.goal_lock3 === 0 ||
        isBefore(
          lockDate,
          addDays(parse(authUser.goal_lock3, "yyyy-MM-dd", new Date()), 1)
        );
      if (!returnValue.goal4Disabled) returnValue.scrollIndex = 3;

      returnValue.goal5Disabled =
        authUser.goal_lock4 === 0 ||
        isBefore(
          lockDate,
          addDays(parse(authUser.goal_lock4, "yyyy-MM-dd", new Date()), 1)
        );
      if (!returnValue.goal5Disabled) returnValue.scrollIndex = 4;

      returnValue.goal6Disabled =
        authUser.goal_lock5 === 0 ||
        isBefore(
          lockDate,
          addDays(parse(authUser.goal_lock5, "yyyy-MM-dd", new Date()), 1)
        );
      if (!returnValue.goal6Disabled) returnValue.scrollIndex = 5;
    }

    return returnValue;
  }, [startDate, isClient, authUser]);

  return data;
}
