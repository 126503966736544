import * as React from "react";
import { isDesktop } from "react-device-detect";
import { FaArrowCircleLeft } from "@react-icons/all-files/fa/FaArrowCircleLeft";
import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight";
import useDragScroll from "../../hooks/useDragScroll";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useBasixData from "../../hooks/useBasixData";
import IntroTile from "./IntroTile";
import GoalTile from "./GoalTile";

export default function BackToBasix() {
  const ref = React.useRef(null);
  const { width } = useWindowDimensions();
  const goalData = useBasixData();
  // TODO: put back after users are used to the new layout
  // const { scrollIndex } = goalData;
  const scrollIndex = 0;
  const dragProps = useDragScroll(width * 0.45 * scrollIndex);

  React.useEffect(() => {
    if (!isDesktop) {
      if (ref.current) {
        ref.current.scrollLeft = width * 0.45 * scrollIndex;
      }
    }
  }, [width, scrollIndex]);

  const nodes = (
    <>
      <IntroTile />
      <GoalTile
        tileStyle="two"
        title="H2O"
        to={`/basix/1`}
        locked={goalData.goal1Disabled}
      />
      <GoalTile
        tileStyle="three"
        title="Eat Your Wheaties!"
        to={`/basix/2`}
        locked={goalData.goal2Disabled}
      />
      <GoalTile
        tileStyle="four"
        title="ZZzZZzz"
        to={`/basix/3`}
        locked={goalData.goal3Disabled}
      />
      <GoalTile
        tileStyle="five"
        title="Don't Do Drugs!"
        to={`/basix/4`}
        locked={goalData.goal4Disabled}
      />
      <GoalTile
        tileStyle="six"
        title="Prevent the Binge!"
        to={`/basix/5`}
        locked={goalData.goal5Disabled}
      />
      <GoalTile
        tileStyle="seven"
        title="Chill out!"
        to={`/basix/6`}
        locked={goalData.goal6Disabled}
      />
    </>
  );

  const arrows = (
    <div className="arrows">
      <div>
        <FaArrowCircleLeft color="white" size={32} />
      </div>
      <div>
        <FaArrowCircleRight color="white" size={32} />
      </div>
    </div>
  );

  if (!isDesktop) {
    return (
      <div className="back-to-basix-scroller-wrapper">
        <div className="back-to-basix-scroller" ref={ref}>
          {nodes}
        </div>
        {arrows}
      </div>
    );
  }

  return (
    <div className="back-to-basix-scroller-wrapper">
      <div className="back-to-basix-scroller" {...dragProps}>
        {nodes}
      </div>
      {arrows}
    </div>
  );
}
