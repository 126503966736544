import * as React from "react";
import { navigate } from "gatsby";
import { isDesktop } from "react-device-detect";
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { Link } from "gatsby-plugin-modal-routing-3";
import * as ROUTES from "../../constants/routes";

export default function GoalTile({ tileStyle, title, to, locked }) {
  const titleParts = title.split(" ");
  const halfRoundedUp = Math.ceil(titleParts.length / 2.0);
  const firstHalfTitle = titleParts.slice(0, halfRoundedUp).join(" ");
  const secondHalfTitle = titleParts.slice(halfRoundedUp).join(" ");
  const titleNode = (
    <>
      <span className="title first">{firstHalfTitle.toUpperCase()} </span>
      <span className="title second">{secondHalfTitle.toUpperCase()}</span>
    </>
  );

  return (
    <div className={`tile ${tileStyle} goal`}>
      <div />
      <div className="title-container">
        {!locked && (
          <Link className="light-link" to={to} asModal>
            {titleNode}
          </Link>
        )}
        {locked && titleNode}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {locked === false && (
          <div className="action-buttons">
            <button
              className={`fancy ${isDesktop ? "log-goal" : ""}`}
              onClick={() => navigate(ROUTES.BALANCE_TRACKER)}
            >
              Log Goal
            </button>
          </div>
        )}
        {locked && (
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FaLock
              style={{
                color: "black",
                textShadow: "1px 1px darkgray",
                fontWeight: "bold",
              }}
              size={isDesktop ? 36 : 24}
            />
            <div style={{ marginTop: "1rem" }}>
              <span
                style={{
                  color: "black",
                  textShadow: "1px 1px darkgray",
                  fontWeight: "bold",
                  fontSize: isDesktop ? "1.5rem" : "1rem",
                }}
              >
                Keep working!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
